<template>
  <div class="flex items-center justify-center h-screen bg-gray-300">
		<!-- Container -->
		<div class="container mx-auto">
			<div class="flex justify-center px-6 my-12">
				<!-- Row -->
				<div class="w-full xl:w-3/5 lg:w-11/12 flex shadow-xl border-1 border-gray-400">
					<!-- Col -->
					<div
            id="animated"
						class="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
					>
          </div>
					<!-- Col -->
					<div class="w-full lg:w-1/2 bg-white p-5  lg:rounded-l-none rounded-r-lg">
						<div class="px-8  text-center">
              <h4 class="font-semibold mt-5 mb-5 pb-1 text-3xl">Login</h4>
						</div>
            <form>
              <!-- BEGIN: Email --->
              <div>
                <v-text-field placeholder="Email"
                  v-model="email"  
                  required outlined 
                  dense
                />
              </div>
              <!-- END: Email --->
              <!-- BEGIN: Password --->
              <div>
                <v-text-field v-model="password" 
                  dense
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" 
                  :rules="[passwordRules]"
                  :type="show ? 'password' : 'text'"
                  class="input-group--focused" 
                  outlined placeholder="Enter password" 
                  @click:append="show = !show" 
                />
              </div>
              <!-- END: Password --->
              <div class="text-center pt-1 mb-5 pb-1">
                <v-btn block color="primary" depressed @click="login()" large>
                  LOGIN
                </v-btn>
              </div>
              <div class="flex items-center justify-between pb-2">
                <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer" @click="goToRegister()">Don't have an account?</span>
                <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer" @click="goToForgotPassword()">Forgot Password?</span>
              </div>
            </form>
          </div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
export default {
  data: () => ({
    username: "",
    email: null,
    password: null,
    show: true,
    isLoading: false,
    valid: true,
    nameRules: [(v) => !!v || "This field is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ]
  }),

  methods: {
    login() {
      this.loading = true;
      if (this.email) {
        if (this.password) {
          let data = {
            email: this.email,
            password: this.password,
          };
          this.$store.dispatch("userLogin", data);
        } else {
          Swal.fire({
            toast: true,
            position: "center",
            icon: "error",
            title: "please provide a password",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          icon: "error",
          title: "please provide email",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    handleRegister() {
      if (this.username) {
        if (this.email) {
          if (this.password) {
            if (this.password == this.password2) {
              this.loading = true;
              let data = {
                username: this.username,
                email: this.email,
                password1: this.password,
                password2: this.password2,
              };
              this.$store.dispatch("userRegister", data);
            } else {
              Swal.fire({
                toast: true,
                position: "center",
                icon: "error",
                title: "passwords must match",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              toast: true,
              position: "center",
              icon: "error",
              title: "please provide a password",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            toast: true,
            position: "center",
            icon: "error",
            title: "please provide an email",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          icon: "error",
          title: "please provide a username",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    goToRegister(){
      this.$router.push({ name: "Register" })
    },

    goToForgotPassword(){
      this.$router.push({ name: "Forgot Password" })
    }
  },
  mounted() {
    this.loading = false;
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters.getLoginLoading;
      },
      set(value) {
        this.$store.commit("SET_LOGIN_LOADING", value);
      },
    },
  },
};
</script>

<style scoped>
.emblem-style {
  border: 2px solid #efefef;
  box-shadow: 0 0 8px rgb(0 0 0 / 50%);
  padding: 5px;
  border-radius: 50%;
}
.loginBtn {
  margin-left: 50px;
}

#animated {
  background: url("../../assets/images/Kilimanjaro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>